import React from 'react';
import ProjectPage from '../../components/ProjectPage/ProjectPage';
import SimpleEntryGallery from '../../components/SimpleEntryGallery/SimpleEntryGallery';
import { useGymCoachInfo } from '../../content/projects/gymcoach.project';
import { PageComponent } from '../../types/gatsby.types';
import './ProjectPageGymCoach.scss';

type ProjectPageGymCoachProps = {}

const ProjectPageGymCoach: PageComponent<ProjectPageGymCoachProps> = props => {
  const metaInfo = useGymCoachInfo();
  return <ProjectPage
    {...props} // always include
    project={metaInfo}
  >
    <SimpleEntryGallery entry={metaInfo} mobileSlidesPosition={0} />
  </ProjectPage>
}

export default ProjectPageGymCoach;